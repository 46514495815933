<template>
  <el-main>
    <el-form label-width="90px">
      <el-form-item label="*是否开启">
        <el-switch v-model="is_open" :active-value="1" :inactive-value="0"></el-switch>
      </el-form-item>
      <el-form-item label="*会员折扣">
        <el-table :data="discount_data" border :header-cell-style="{ 'background-color': '#F8F9FA' }" style="width: 650px">
          <el-table-column prop="name" label="*会员身份" align="left">
            <template v-slot="{ row }">
              <el-select v-model="row.type" multiple clearable>
                <el-option v-for="(item, index) in identity" :key="index" :label="item.name" :value="item.type"></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="discount" label="*会员折扣" align="left" width="300px">
            <template v-slot="{ row, $index }">
              <div class="discount">
                <el-input v-model="row.discount" type="number" min="0" max="9.9"></el-input>
                <i class="el-icon-circle-close" @click="discount_data.splice($index, 1)"></i>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="add">
          <el-button type="text" icon="el-icon-plus" @click="add">添加会员身份（身份组合）</el-button>
        </div>
      </el-form-item>
    </el-form>
    <Preservation @preservation="preservation"></Preservation>
  </el-main>
</template>

<script>
import Preservation from '@/components/preservation';
export default {
  components: {
    Preservation,
  },
  data() {
    return {
      identity: [
        {
          name: '个人会员',
          type: 0,
        },
        {
          name: '企业会员',
          type: 1,
        },
        {
          name: '个人会员商家',
          type: 2,
        },
        {
          name: '企业会员商家',
          type: 3,
        },
        {
          name: '供应商品商家',
          type: 4,
        },
        {
          name: '代销商家',
          type: 5,
        },
        {
          name: '产品合伙人',
          type: 6,
        },
        {
          name: '城市合伙人',
          type: 7,
        },
        {
          name: '市场合伙人',
          type: 8,
        },
      ],
      discount_data: [],
      is_open: 0,
    };
  },
  created() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      this.$axios.post(this.$api.gateway.member.discountInfo).then(res => {
        if (res.code == 0) {
          let info = res.result;
          this.is_open = info.is_open;
          this.discount_data = JSON.parse(info.discount_data);
          for (let i in this.discount_data) this.discount_data[i].type = this.discount_data[i].type.map(Number);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    add() {
      this.discount_data.push({
        discount: '',
        type: '',
      });
    },
    preservation() {
      let type_arr = [];
      for (let i in this.discount_data) {
        if (!this.discount_data[i].discount || !this.discount_data[i].type.length) return this.$message.warning('请将会员折扣信息填写完整');
        if (this.discount_data[i].discount > 9.9 || this.discount_data[i].discount < 0.1) {
          return this.$message.warning('请填写0.1-9.9之间折扣');
        }
        type_arr.push(this.discount_data[i].type);
      }
      if (type_arr.length) {
        for (let i in type_arr) {
          type_arr[i] = type_arr[i].sort(function (a, b) {
            return a - b;
          });
          type_arr[i] = type_arr[i].join();
        }
        let type_arr_filter = type_arr.filter(function (item, index) {
          return type_arr.indexOf(item) === index;
        });
        if (type_arr_filter.length < type_arr.length) return this.$message.warning('身份组合不能重复！');
      }
      this.$axios
        .post(this.$api.gateway.member.discountInfoEdit, {
          is_open: this.is_open,
          discount_data: this.discount_data,
        })
        .then(res => {
          if (res.code == 0) {
            this.$message.success('保存成功');
          } else {
            this.$message.error(res.msg);
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  height: 100%;
  padding-bottom: 50px;
  .el-select {
    width: 100%;
  }
  .discount {
    display: flex;
    align-items: center;
    i {
      font-size: 24px;
      margin-left: 10px;
      cursor: pointer;
    }
  }
}
</style>